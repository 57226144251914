<template>
  <v-card flat :height="height">
    <Loader absolute :loading="loading" />
    <div class="d-flex justify-end">
      <v-btn
        text
        color="warning"
        small
        class="mr-1"
        @click="setZones"
      >
        <v-icon left>mdi-refresh</v-icon>
        Refrescar
      </v-btn>
      <v-btn
        text
        color="primary"
        small
        @click="openZoneCreate"
      >
        <v-icon left>mdi-plus</v-icon>
        Zona
      </v-btn>
    </div>
    <v-row>
      <template v-for="zone in zones">
        <v-col cols="12" md="4" sm="6" :key="`zone-${zone.id}`">
          <v-card outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">
                  {{ zone.name }}
                </div>
                <v-list-item-subtitle>{{ zone.description }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-avatar
                tile
                size="50"
                color="grey"
                rounded
              >
                <v-icon>mdi-map-marker-path</v-icon>
              </v-list-item-avatar>
            </v-list-item>

            <v-card-actions>
              <v-spacer />
              <v-btn
                text
                small
                color="warning"
                @click="editZone(zone)"
              >
                Editar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </template>
    </v-row>
    <ZoneCreate
      ref="zoneCreate"
      @onAddZone="onAddZone"
    />
  </v-card>
</template>

<script>
import ZoneServices from '@/store/services/general/ZoneServices';
import ZoneCreate from './ZoneCreate';
import { mapGetters } from 'vuex';
export default {
  components: {
    ZoneCreate,
  },
  data() {
    return {
      loading: false,
      zones: [],

      height: window.innerHeight - 100,
    };
  },
  created() {
    this.setZones();
  },
  methods: {
    openZoneCreate() {
      this.$refs['zoneCreate'].dialog = true;
    },
    onAddZone(data) {
      if (data.type === 'store') this.zones.unshift(data.zone);
      else {
        const index = this.zones.findIndex(zone => zone.id === data.zone.id);
        this.zones[index].name = data.zone.name;
        this.zones[index].description = data.zone.description;
      }
    },
    async setZones() {
      try {
        this.loading = true;
        const response = await ZoneServices.index(this.user.archon.id);
        this.loading = false;
        this.zones = response.data.data;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    editZone(zone) {
      this.$refs['zoneCreate'].dialog = true;
      this.$refs['zoneCreate'].zoneId = zone.id;
      this.$refs['zoneCreate'].form = Object.assign({}, {
        name: zone.name,
        description: zone.description,
      });
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
}
</script>

<style scoped>

</style>
<template>
  <v-dialog v-model="dialog" width="50%">
    <v-card>
      <Loader absolute :loading="loading" />
      <v-card-title>
        <v-icon left>mdi-map-marker-plus</v-icon>
        {{ zoneId === -1 ? 'Agregar Zona' : 'Editar Zona' }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field
            v-model="form.name"
            label="Nombre de Zona"
            :rules="[isRequired]"
            :error-messages="errorName"
          />
          <v-textarea
            v-model="form.description"
            label="Description"
            rows="2"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="grey lighten-1"
          small
          @click="closeDialog"
        >
          Cerrar
        </v-btn>
        <v-btn
          v-if="zoneId === -1"
          color="success"
          small
          @click="storeZone"
        >
          Guardar
        </v-btn>
        <v-btn
          v-else
          color="warning"
          small
          @click="updateZone"
        >
          Actualizar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ZoneServices from '@/store/services/general/ZoneServices';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      dialog: false,
      loading: false,

      form: {
        name: '',
        description: '',
      },

      errorName: '',

      zoneId: -1,

      // rules
      isRequired: value => !!value || 'Campo requerido',
    };
  },
  methods: {
    closeDialog() {
      this.resetData();
      this.dialog = false;
    },
    async updateZone() {
      if (this.$refs.form.validate()) {
        try {
          this.errorName = '';
          this.loading = true;
          await ZoneServices.update(this.user.archon.id, this.zoneId, this.form);
          this.$emit('onAddZone', {
            zone: {
              id: this.zoneId,
              name: this.form.name,
              description: this.form.description,
            },
            type: 'update',
          });
          this.resetData();
          this.loading = false;
          this.dialog = false;
        } catch (error) {
          this.loading = false;
          console.log(error);
         if (error.response.status === 422) {
            this.errorName = error.response.data.errors?.name?.[0] ?? '';
          }
        }
      }
    },
    async storeZone() {
      if (this.$refs.form.validate()) {
        try {
          this.errorName = '';
          this.loading = true;
          const response = await ZoneServices.store(this.user.archon.id, this.form);
          this.$emit('onAddZone', {
            zone: response.data,
            type: 'store',
          });
          this.resetData();
          this.loading = false;
          this.dialog = false;
        } catch (error) {
          this.loading = false;
          console.log(error);
         if (error.response.status === 422) {
            this.errorName = error.response.data.errors?.name?.[0] ?? '';
          }
        }
      }
    },
    resetData() {
      this.form.name = '';
      this.form.description = '';
      this.zoneId = -1;

      this.$refs.form.resetValidation();
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
}
</script>

<style scoped>

</style>
import ApiService from '../Api';

class ZoneServices extends ApiService {
  constructor() {
    super('/archons');
  }

  index(archon, params = {}) {
    return this.api.get(`${this.resource}/${archon}/zones`, { params });
  }

  store(archon, body) {
    return this.api.post(`${this.resource}/${archon}/zones`, body);
  }

  update(archon, zone, body) {
    return this.api.put(`${this.resource}/${archon}/zones/${zone}`, body)
  }
}

export default new ZoneServices();
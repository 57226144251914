import api from '../../api';

export default class ApiService {
  constructor(resource) {
    this.resource = resource;
    this.api = api;
  }

  getAll(params) {
    if (params) {
      return this.api.get(`${this.resource}`, { params });
    }
    return this.api.get(`${this.resource}`);
  }

  getResource(id, params) {
    if (params) {
      return this.api.get(`${this.resource}/${id}`, { params });
    }
    return this.api.get(`${this.resource}/${id}`);
  }

  create(payload) {
    return this.api.post(`${this.resource}`, payload);
  }

  update(id, payload) {
    return this.api.put(`${this.resource}/${id}`, payload);
  }

  remove(id) {
    return this.api.delete(`${this.resource}/${id}`);
  }
}
